.cardTask {
    height: 240px;
    background-color: white;
    border-radius: 10px !important;
    border: 1px outset #00000091;
    padding: 15px 15px 35px 36px;
    display: grid;
}
.cardTask::before {
    content: "";
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 5 !important;
    height: -webkit-fill-available;
    width: 14px;
    background: linear-gradient(180deg, #FFBF00 0%, #F6E1A4 100%);
    border-radius: 10px 0 0 10px;
  }
  .cardTaskSubmission {
    height: 245px;
    overflow-y: scroll !important;
    background-color: white;
    border-radius: 10px !important;
    border: 1px outset #00000091;
    padding: 15px 15px 35px 36px;
    display: grid;
}
.cardTaskSubmission::before {
    content: "";
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 5 !important;
    height: 300px;
    width: 10px;
    background: linear-gradient(180deg, #FFBF00 0%, #F6E1A4 100%);
    border-radius: 10px 0 0 10px;
  }
  .card2 {
    display: block;
    top: 0px;
    position: relative;
    max-width: 262px;
    border-radius: 4px;
    margin: 12px 0px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    transition: all 0.2s ease-out !important;
  }
  
  .card2:hover, .selectedCard {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    margin: 6px 3px 6px 0px;
  }

  .cardLeadGuide {
    background-color: white;
    border-radius: 10px !important;
    border: 1px outset #00000091;
    padding: 15px 15px 35px 36px;
    display: block;
}
  
.ellipsis-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px !important;
    min-height: 75px;
}
.ellipsis-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* line-height: 24px !important; */
    min-height: 75px;
}
.ellipsis-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
}
.cursor {
    cursor: pointer;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .loader {
    border: 4px solid #f3f3f3; /* Light gray */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Track background color */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Thumb color */
    border-radius: 6px; /* Rounded corners for the thumb */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Thumb color on hover */
  }
  
  #activityInsCard>div {
    /* overflow-y: scroll; */
  }
  .taskInsClass {
    width: 12vw;
}

.slick-dots li button:before {
  font-size: 10px !important; /* Adjust the font size as needed */
  }

  .slick-prev:before, .slick-next:before{
    color: black !important;
    font-size: 34px !important;
  }

  .slick-prev{
    left: -75px !important;
    transform: translate(0, -100%) !important;
    z-index: 99;
  }
  .slick-next{
    right: -60px !important;
  }
  .slick-dots{
    bottom: -40px !important;
  }