.activityCard {
  cursor: pointer;
}

.activityCard.closed {
  cursor: initial;
  opacity: .4;
  background: #f2f2f2;
  box-shadow: none;
  border-color: #883b43;
}

.activityCard.closed :global(.MuiCardMedia-root) {
  filter: grayscale(1);
}
